import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Box, Paper, Typography, Grid, Button, Pagination, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import BedIcon from '@mui/icons-material/Bed';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import BathtubIcon from '@mui/icons-material/Bathtub';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './style.css';
import api from "../../../services/api";

const Carousel = ({ title, subTitle, operationType, propertyTypes, finalType, objectFilter }: any) => {
    const [properties, setProperties] = useState([]);
    const [sections, setSections] = useState([]);
    const [selectedSections, setSelectedSections]: any = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalPerPage, setTotalPerPage] = useState(10);
    const states = objectFilter && objectFilter.estados && objectFilter.estados.length > 0 ? objectFilter.estados.map((state: any) => state.label) : null;
    const cities = objectFilter && objectFilter.cidades && objectFilter.cidades.length > 0 ? objectFilter.cidades.map((city: any) => city.label) : null;
    const neighborhoods = objectFilter && objectFilter.bairros && objectFilter.bairros.length > 0 ? objectFilter.bairros.map((neighborhood: any) => neighborhood.label) : null;
    const filter = objectFilter && objectFilter.filtro && objectFilter.filtro.length > 0
        ? objectFilter.filtro.map((item: any) => item.label).join('+')
        : null;

    const fetchData = async () => {
        const response = await api.post('/latitude-properties', {
            "novidade": true,
            "numeroPagina": currentPage,
            "tamanhoPagina": totalPerPage,
            "empresaId": "025c1450-3ab4-4e5d-8e3c-d69917235546",
            "operacaoImobiliaria": operationType,
            "finalidades": finalType ? [finalType] : null,
            "tiposImovel": propertyTypes ? [propertyTypes] : null,
            "estados": states,
            "cidades": cities,
            "bairros": neighborhoods,
            "filtro": filter  // Passando a string formatada
        });
        setProperties(response.data.data);
        setTotalPages(response.data.total);

        const initialSelections = response.data.data.reduce((acc: any, property: any) => ({
            ...acc,
            [property.codImovel]: {}
        }), {});
        setSelectedSections(initialSelections);
    };

    useEffect(() => {
        fetchSections();
        fetchData();
    }, [currentPage, operationType, propertyTypes, finalType, objectFilter]);


    const fetchSections = async () => {
        const response = await api.get('list-sections');
        setSections(response.data);
    };

    const handleSelectSection = (propertyId: number, sectionId: number) => {
        setSelectedSections((prev: any) => {
            const propertySections = prev[propertyId] || {};
            return {
                ...prev,
                [propertyId]: {
                    ...propertySections,
                    [sectionId]: !propertySections[sectionId]
                }
            };
        });
    };



    const handleChangePage = (event: any, value: any) => {
        setCurrentPage(value);
    };

    if (!properties) return <div>Loading...</div>;

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: properties.length === 1 ? 1 : 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true // Mantém os dots ativos em telas com largura até 1024px
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false // Oculta os dots para dispositivos com tela menor que 600px
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    dots: false // Oculta os dots para dispositivos com tela menor que 480px
                }
            }
        ]
    };
    const renderSectionsDropdown = (property: any) => (
        <FormControl fullWidth>
            <InputLabel id={`sections-label-${property.codImovel}`}>Adicionar</InputLabel>
            <Select
                labelId={`sections-label-${property.codImovel}`}
                multiple
                value={Object.keys(selectedSections[property.codImovel] || {}).filter(key => selectedSections[property.codImovel][key])}
                renderValue={(selected) => sections.filter((section: any) => selected.includes(section.id)).map((section: any) => section.name).join(', ')}
                MenuProps={{ PaperProps: { style: { maxHeight: 224 } } }}
            // Remova o onClose para evitar chamadas automáticas
            >
                {sections.map((section: any) => (
                    <MenuItem key={section.id} value={section.id}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={!!(selectedSections[property.codImovel] && selectedSections[property.codImovel][section.id])}
                                onChange={() => handleSelectSection(property.codImovel, section.id)}
                            />}
                            label={section.name}
                        />
                    </MenuItem>
                ))}
                <Button variant="outlined" sx={{ borderRadius: 0 }} onClick={() => handleAddProperty(property)}>
                    Concluir
                </Button>
            </Select>
        </FormControl>
    );


    function truncateString(str: string, num: number) {
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    }

    // Caminho base para as imagens dentro do diretório public
    const basePath = process.env.PUBLIC_URL + '/assets/images/properties/';



    const handleAddProperty = async (property: any) => {
        // Extrair IDs das seções selecionadas para essa propriedade
        const sectionIds = Object.keys(selectedSections[property.codImovel] || {})
            .filter(key => selectedSections[property.codImovel][key])
            .map(Number);  // Converte as chaves de string para número se necessário

        try {
            const response = await api.post('insert-property', {
                latitude_id: property.codImovel,
                friendly_title: property.tituloAmigavel,
                description: property.caracteristica.caracteristicas,
                property_type: property.tipoImovel.descricao,
                state: property.logradouro.bairro.cidade.estado.nome,
                state_abbreviation: property.logradouro.bairro.cidade.estadoId,
                city: property.logradouro.bairro.cidade.nome,
                neighborhood: property.tipoImovel.descricao,
                sale_price: property.valorVenda,
                rent_price: property.valorAluguel,
                area: property.caracteristica.areaConstruida,
                bedrooms: property.caracteristica.quantidadeQuartos,
                suites: property.caracteristica.quantidadeSuites,
                parking_spots: property.caracteristica.vagasGaragem,
                images: property.imagens,
                section_ids: sectionIds  // Incluir IDs das seções selecionadas
            });
            if (response.status === 200) {
                alert('Imóvel adicionado com sucesso!');
            } else {
                alert('Falha ao adicionar o imóvel.');
            }
        } catch (error) {
            console.error('Erro ao adicionar imóvel:', error);
            alert('Erro ao adicionar o imóvel.');
        }
    };



    return (
        <>
            <Slider {...settings}>
                {properties.map((property: any, index) => (
                    <Box key={index} className="carouselImageContainer">
                        <img src={property.imagens[0]?.arquivo || `${basePath}no-image.png`} alt={`Imagem ${index + 1}`} />
                        <Paper sx={{ position: 'relative', p: 2, margin: 'auto', maxWidth: 500, minHeight: '370px', flexGrow: 1, backgroundColor: 'transparent', border: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} elevation={4}>
                            <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                <Grid item xs={12}>
                                    <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        sx={{
                                            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {property.logradouro.bairro.cidade.nome} <KeyboardArrowRightIcon /> {property.tipoImovel.descricao}
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        sx={{
                                            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {truncateString(property.tituloAmigavel, 40)}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        color="primary"
                                        gutterBottom
                                        sx={{
                                            fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem" },
                                        }}
                                    >
                                        {property.valorVenda
                                            ? new Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            }).format(property.valorVenda)
                                            : ""}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        color="primary"
                                        gutterBottom
                                        sx={{
                                            fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem" },
                                        }}
                                    >
                                        {property.valorAluguel
                                            ? new Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            }).format(property.valorAluguel)
                                            : ""}
                                    </Typography>

                                    {/* Renderiza o bairro e a cidade apenas se não houver valores para venda e aluguel simultaneamente */}
                                    {!property.valorVenda || !property.valorAluguel ? (
                                        <>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                gutterBottom
                                                sx={{
                                                    fontSize: { xs: "0.7rem", sm: "0.9rem", md: "1rem" },
                                                }}
                                            >
                                                {property.logradouro.bairro.nome}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: { xs: "0.7rem", sm: "0.9rem", md: "1rem" },
                                                }}
                                            >
                                                {property.logradouro.bairro.cidade.nome} - {property.logradouro.bairro.cidade.estadoId}
                                            </Typography>
                                        </>
                                    ) : null}
                                </Grid>

                                <Grid item xs={12} display="flex" sx={{ position: 'absolute', bottom: '40px' }}>

                                    <Grid container direction="row" spacing={2} sx={{ mt: 'auto' }}>
                                        <Grid container direction="row" spacing={2}>
                                            {
                                                property.caracteristica.areaConstruida > 0 && (
                                                    <Grid item xs>
                                                        <Box display="grid" alignItems="center" justifyContent="center">
                                                            <SquareFootIcon color="action" sx={{ margin: '0 auto' }} />
                                                            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '12px' }}>
                                                                {property.caracteristica.areaConstruida} m²
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                property.caracteristica.quantidadeQuartos > 0 && (
                                                    <Grid item xs>
                                                        <Box display="grid" alignItems="center" justifyContent="center">
                                                            <BedIcon color="action" sx={{ margin: '0 auto' }} />
                                                            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '12px' }}>
                                                                {property.caracteristica.quantidadeQuartos} quartos
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                property.caracteristica.quantidadeSuites > 0 && (
                                                    <Grid item xs>
                                                        <Box display="grid" alignItems="center" justifyContent="center">
                                                            <BathtubIcon color="action" sx={{ margin: '0 auto' }} />
                                                            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '12px' }}>
                                                                {property.caracteristica.quantidadeSuites} Suíte
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                property.caracteristica.vagasGaragem > 0 && (
                                                    <Grid item xs>
                                                        <Box display="grid" alignItems="center" justifyContent="center">
                                                            <DriveEtaIcon color="action" sx={{ margin: '0 auto' }} />
                                                            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '12px' }}>
                                                                {property.caracteristica.vagasGaragem} Garagem
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                        <Grid container direction="row" spacing={2} sx={{ margin: 0 }}>
                                            <Grid item xs={8}>
                                                {/* <Button variant="outlined" sx={{ borderRadius: 0 }} onClick={() => handleAddProperty(property)}>
                                                    Concluir
                                                </Button> */}
                                                {renderSectionsDropdown(property)}
                                            </Grid>
                                            <Grid item xs={4} display="grid" alignItems="center" justifyContent="center">
                                                <Typography variant="body2" color="text.secondary">
                                                    {
                                                        (property.valorVenda && property.valorAluguel) ? (
                                                            <>
                                                                Venda/Aluguel
                                                            </>
                                                        ) : property.valorVenda ? (
                                                            <>
                                                                Venda
                                                            </>
                                                        ) : property.valorAluguel ? (
                                                            <>
                                                                Aluguel
                                                            </>
                                                        ) : null
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                ))}
            </Slider>
            {totalPages > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <Pagination count={Math.ceil(totalPages / totalPerPage)} page={currentPage} onChange={handleChangePage} />
                </Box>
            )}



        </>
    );
};

export default Carousel;
