import React, { useState } from 'react';
import { useMatch, Link as RouterLink } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Box, Collapse } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import WebIcon from '@mui/icons-material/Web';
import { useAuth } from '../../contexts/AuthContext';

interface SidebarNavListProps {
    handleOpenSettings: () => void;
    handleLogout: () => void;
}

const SidebarNavList: React.FC<SidebarNavListProps> = ({ handleOpenSettings, handleLogout }) => {
    const { userAccessLevel } = useAuth();
    const [openProperties, setOpenProperties] = useState(false);
    const [openWebsite, setOpenWebsite] = useState(false);

    const propertyPath = userAccessLevel === 1 ? '/admin/home' : '/cliente/home';
    const matchProperties = useMatch(propertyPath);

    const myPropertiesPath = userAccessLevel === 1 ? '/admin/meus-imoveis' : '/cliente/meus-imoveis';
    const matchMyProperties = useMatch(myPropertiesPath);

    const websitePath = userAccessLevel === 1 ? '/admin/website' : '/cliente/website';
    const matchWebsite = useMatch(websitePath);

    const handleClickProperties = () => {
        setOpenProperties(!openProperties);
    };

    const handleClickWebsite = () => {
        setOpenWebsite(!openWebsite);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleClickProperties}>
                            <ListItemIcon>
                                <ApartmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Imóveis" />
                            {openProperties ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openProperties} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={RouterLink}
                                to={propertyPath}
                                selected={!!matchProperties}
                            >
                                <ListItemText primary="Rede Latitude" />
                            </ListItemButton>
                        </List>
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={RouterLink}
                                to={myPropertiesPath}
                                selected={!!matchMyProperties}
                            >
                                <ListItemText primary="Meus Imóveis" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleClickWebsite}>
                            <ListItemIcon>
                                <WebIcon />
                            </ListItemIcon>
                            <ListItemText primary="Website" />
                            {openWebsite ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openWebsite} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={RouterLink}
                                to="/admin/escritorios"
                                selected={!!matchWebsite}
                            >
                                <ListItemText primary="Escritórios" />
                            </ListItemButton>
                        </List>
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={RouterLink}
                                to="/admin/universo-vext"
                                selected={!!matchWebsite}
                            >
                                <ListItemText primary="Universo Vext" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </List>
                <Divider />
            </Box>
            <Box>
                <Divider />
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleOpenSettings}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Ajustes" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleLogout}>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="Sair" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Box>
    );
};

export default SidebarNavList;
