import React, { useEffect, useState, useRef } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Grid, CircularProgress, Button, Snackbar, Alert } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CardUniverse from '../../components/CardUniverse';
import monochrome from '../../Themes/Default';
import Main from '../../components/Main';
import api from '../../services/api';

interface ImageData {
  id: number;
  path: string;
}

const VextUniverse: React.FC = () => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('info');
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Fetch images on mount
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await api.get('/vext-universe');
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching images:', error);
        showSnackbar('Erro ao carregar imagens.', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  // Add a new image
  const handleAddImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await api.post('/vext-universe', formData);
        setImages((prevImages) => [...prevImages, response.data]);
        showSnackbar('Imagem adicionada com sucesso!', 'success');
      } catch (error) {
        console.error('Error uploading image:', error);
        showSnackbar('Erro ao adicionar a imagem.', 'error');
      }
    }
  };

  // Remove an image
  const handleRemoveImage = async (id: number) => {
    try {
      await api.delete(`/vext-universe/${id}`);
      setImages((prevImages) => prevImages.filter((image) => image.id !== id));
      showSnackbar('Imagem excluída com sucesso!', 'success');
    } catch (error) {
      console.error('Error deleting image:', error);
      showSnackbar('Erro ao excluir a imagem.', 'error');
    }
  };

  // Show snackbar
  const showSnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  // Handle close snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={monochrome}>
      <Main>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <h1>Universo Vext</h1>
          <Button
            variant="contained"
            startIcon={<AddPhotoAlternateIcon />}
            onClick={() => fileInputRef.current?.click()}
            sx={{
              margin: 2,
              bgcolor: '#C7FD06',
              color: 'black',
              '&:hover': {
                bgcolor: '#C7FD06',
              },
            }}
          >
            Adicionar Imagem
          </Button>
          <input
            type="file"
            hidden
            ref={fileInputRef}
            onChange={handleAddImage}
            accept="image/*"
          />
        </Box>
        {images.length > 0 ? (
          <Grid container spacing={2}>
            {images.map((image) => (
              <Grid item xs={12} md={3} key={image.id} sx={{ height: { xs: 300, md: 400 } }}>
                <CardUniverse
                  src={image.path}
                  onDelete={() => handleRemoveImage(image.id)}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '300px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: '2px 2px 10px #0000000f;',
            }}
          >
            Ainda não há imagens
          </Box>
        )}
        {/* Snackbar for feedback */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Main>
    </ThemeProvider>
  );
};

export default VextUniverse;
