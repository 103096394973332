import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Login from "./pages/Login";
import Home from "./pages/Home";
import RecoverPass from "./pages/RecoverPass";
import PrivateRoute from "./utils/PrivateRoute";
import Unauthorized from "./pages/HttpError/401";
import Register from "./pages/Register";
import NotFound from "./pages/HttpError/404";
import AdminHome from "./pages/Admin/Home";
import MyProperties from "./pages/Admin/MyProperties";
import Offices from "./pages/Admin/Offices";
import VextUniverse from "./pages/VextUniverse";

export default function App() {
  return (
      <AuthProvider>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/unauthorized" element={<Unauthorized />} />

          <Route path="/" element={<Login />} />
          <Route path="/registro" element={<Register />} />
          <Route path="/redefinir-senha/:token" element={<RecoverPass />} />

          <Route element={<PrivateRoute />}>
            <Route path="/home" element={<Home />} />
          </Route>

          <Route element={<PrivateRoute accessLevel={1} />}>
            <Route path="/admin/home" element={<AdminHome />} />
            <Route path="/admin/meus-imoveis" element={<MyProperties />} />
            <Route path="/admin/escritorios" element={<Offices />} />
            <Route path="/admin/universo-vext" element={<VextUniverse />} />
          </Route>

          <Route element={<PrivateRoute accessLevel={2} />}>
            {/* Adicione rotas específicas para o nível de acesso 2, se houver */}
          </Route>
        </Routes>
      </AuthProvider>
  );
}
