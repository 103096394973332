import React from 'react';
import { Card, CardMedia, CardActions, IconButton, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface ImageCardProps {
  src: string;
  onDelete: () => void;
}

const CardUniverse: React.FC<ImageCardProps> = ({ src, onDelete }) => {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardMedia
        component="img"
        image={src}
        alt="Gallery image"
        sx={{
          flexGrow: 1,
          objectFit: 'cover',
          height: '200px', // Altura fixa para a imagem
        }}
      />
      <Box
        sx={{
          bgcolor: 'background.paper',
          p: 1,
          height: '60px', // Altura fixa para o rodapé
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CardActions disableSpacing>
          <IconButton aria-label="delete image" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Box>
    </Card>
  );
};

export default CardUniverse;