import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Grid,
    IconButton,
    Autocomplete,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../../services/api';
import './style.css';

const brazilianStates = [
    { name: 'Acre', abbreviation: 'AC' },
    { name: 'Alagoas', abbreviation: 'AL' },
    { name: 'Amapá', abbreviation: 'AP' },
    { name: 'Amazonas', abbreviation: 'AM' },
    { name: 'Bahia', abbreviation: 'BA' },
    { name: 'Ceará', abbreviation: 'CE' },
    { name: 'Distrito Federal', abbreviation: 'DF' },
    { name: 'Espírito Santo', abbreviation: 'ES' },
    { name: 'Goiás', abbreviation: 'GO' },
    { name: 'Maranhão', abbreviation: 'MA' },
    { name: 'Mato Grosso', abbreviation: 'MT' },
    { name: 'Mato Grosso do Sul', abbreviation: 'MS' },
    { name: 'Minas Gerais', abbreviation: 'MG' },
    { name: 'Pará', abbreviation: 'PA' },
    { name: 'Paraíba', abbreviation: 'PB' },
    { name: 'Paraná', abbreviation: 'PR' },
    { name: 'Pernambuco', abbreviation: 'PE' },
    { name: 'Piauí', abbreviation: 'PI' },
    { name: 'Rio de Janeiro', abbreviation: 'RJ' },
    { name: 'Rio Grande do Norte', abbreviation: 'RN' },
    { name: 'Rio Grande do Sul', abbreviation: 'RS' },
    { name: 'Rondônia', abbreviation: 'RO' },
    { name: 'Roraima', abbreviation: 'RR' },
    { name: 'Santa Catarina', abbreviation: 'SC' },
    { name: 'São Paulo', abbreviation: 'SP' },
    { name: 'Sergipe', abbreviation: 'SE' },
    { name: 'Tocantins', abbreviation: 'TO' },
];

const propertyTypes = ['Casa', 'Apartamento', 'Terreno', 'Comercial'];

const CreatePropertyForm = ({ open, onClose, onCreate, property }: any) => {
    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: property || {},
    });
    const [sections, setSections]: any = useState([]);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const [imagesToRemove, setImagesToRemove] = useState<number[]>([]);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        // Função para buscar as seções
        const fetchSections = async () => {
            try {
                const response = await api.get('list-sections');
                setSections(response.data); // Atualiza a lista de seções disponíveis

                if (property && property.section_ids) {
                    // Mapeia os IDs de seção para objetos correspondentes no modo de edição
                    const selectedSections = response.data.filter((section: { id: any }) =>
                        property.section_ids.includes(section.id)
                    );
                    setValue('section_ids', selectedSections); // Atualiza o estado do formulário
                }
            } catch (error) {
                console.error('Failed to fetch sections', error);
            }
        };

        fetchSections(); // Sempre busca as seções disponíveis

        if (property) {
            reset(property);

            if (property.images) {
                setImagePreviews(property.images.map((img: { path: any }) => img.path));
            }

            if (property.sections) {
                setValue('section_ids', property.sections); // Configura as seções associadas
            }
        }
    }, [property, reset, setValue]);



    const onSubmit = async (data: any) => {
        const formData = new FormData();

        // Adiciona campos do formulário ao FormData
        for (const key in data) {
            if (key !== 'section_ids') {
                if (data[key] !== null && data[key] !== undefined && data[key] !== '') {
                    formData.append(key, data[key]);
                }
            }
        }

        // Adiciona seções selecionadas ao FormData
        if (data.section_ids && data.section_ids.length > 0) {
            data.section_ids.forEach((section: any) => {
                formData.append('section_ids[]', section.id.toString());
            });
        }

        // Adiciona imagens selecionadas ao FormData
        selectedFiles.forEach((file) => {
            formData.append('images', file);
        });

        // Adiciona imagens a serem removidas ao FormData
        imagesToRemove.forEach((imgId) => {
            formData.append('imagesToRemove', imgId.toString());
        });

        // Chama a função onCreate com o FormData
        onCreate(formData);

        // Reseta o formulário e o estado de imagens
        reset();
        setSelectedFiles([]);
        setImagePreviews([]);
        setImagesToRemove([]);
        setShowAlert(false);
        onClose();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const newFiles = Array.from(files);
            setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
            const previews = newFiles.map((file) => URL.createObjectURL(file));
            setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
        }
    };

    const handleRemoveImage = (index: number, existingImage: boolean) => {
        if (existingImage && property && property.images[index]) {
            const imgId = property.images[index].id;
            setImagesToRemove((prev) => {
                if (!prev.includes(imgId)) {
                    return [...prev, imgId];
                }
                return prev;
            });
        }
        setImagePreviews(imagePreviews.filter((_, i) => i !== index));
        if (!existingImage) {
            setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '80%', md: '60%' },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '8px',
                    maxHeight: '90vh',
                    overflowY: 'hidden', // Permite rolagem
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '60px'
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* Header */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '16px',
                            borderBottom: '1px solid #ddd',
                            backgroundColor: 'background.default',
                            borderTopLeftRadius: '8px',
                            borderTopRightRadius: '8px',
                            zIndex: 1
                        }}
                    >
                        <Typography variant="h6">
                            {property ? 'Editar Imóvel' : 'Cadastro de Imóvel'}
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
    
                    {/* Body */}
                    <Box
                        sx={{
                            mb: '20px',
                            padding: '16px',
                            maxHeight: {xs:'450px', sm: '400px'},
                            overflowY: 'auto', // Aplica rolagem apenas no conteúdo
                            flex: 1, 
                        }}
                    >
                        {showAlert && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                Por favor, preencha todos os campos obrigatórios.
                            </Alert>
                        )}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="friendly_title"
                                    control={control}
                                    rules={{ required: 'Este campo é obrigatório' }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Título *"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.friendly_title}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Descrição"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={4}
                                        />
                                    )}
                                />
                            </Grid>
    
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="state"
                                    control={control}
                                    rules={{ required: 'Este campo é obrigatório' }}
                                    render={({ field }) => (
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.state}
                                        >
                                            <InputLabel>Estado </InputLabel>
                                            <Select
                                                {...field}
                                                label="Estado"
                                                onChange={(event) => {
                                                    field.onChange(event);
                                                    const selectedState = event.target.value;
                                                    const abbreviation =
                                                        brazilianStates.find(
                                                            (s) => s.name === selectedState
                                                        )?.abbreviation || '';
                                                    setValue('state_abbreviation', abbreviation);
                                                }}
                                            >
                                                {brazilianStates.map((state) => (
                                                    <MenuItem key={state.name} value={state.name}>
                                                        {state.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="state_abbreviation"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label=""
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="city"
                                    control={control}
                                    rules={{ required: 'Este campo é obrigatório' }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Cidade *"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.city}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="neighborhood"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField {...field} label="Bairro" variant="outlined" fullWidth />
                                    )}
                                />
                            </Grid>
    
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="sale_price"
                                    control={control}
                                    render={({ field }) => (
                                        <NumericFormat
                                            customInput={TextField}
                                            label="Preço de Venda"
                                            variant="outlined"
                                            fullWidth
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix="R$ "
                                            decimalScale={2}
                                            fixedDecimalScale
                                            value={field.value}
                                            onValueChange={(values) => {
                                                const numericValue = values.floatValue || null;
                                                field.onChange(numericValue);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="area"
                                    control={control}
                                    rules={{ required: 'Este campo é obrigatório' }}
                                    render={({ field }) => (
                                        <NumericFormat
                                            customInput={TextField}
                                            label="Área *"
                                            variant="outlined"
                                            fullWidth
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            suffix=" m²"
                                            decimalScale={2}
                                            value={field.value}
                                            onValueChange={(values) => {
                                                const numericValue = values.floatValue || null;
                                                field.onChange(numericValue);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
    
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="property_type"
                                    control={control}
                                    rules={{ required: 'Este campo é obrigatório' }}
                                    render={({ field }) => (
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.property_type}
                                        >
                                            <InputLabel>Tipo de Imóvel</InputLabel>
                                            <Select {...field} label="Tipo de Imóvel *">
                                                {propertyTypes.map((type) => (
                                                    <MenuItem key={type} value={type}>
                                                        {type}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="section_ids"
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            multiple
                                            options={sections}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Seções"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            value={field.value || []}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <input
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    style={{ marginTop: 16 }}
                                />
                                <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
                                    {imagePreviews.map((src, index) => (
                                        <Box key={index} position="relative">
                                            <img
                                                src={src}
                                                alt={`preview ${index}`}
                                                width={100}
                                                height={100}
                                                style={{ objectFit: 'cover' }}
                                            />
                                            <IconButton
                                                size="small"
                                                color="error"
                                                onClick={() =>
                                                    handleRemoveImage(
                                                        index,
                                                        property && index < property.images.length
                                                    )
                                                }
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
    
                    {/* Footer */}
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            padding: '16px',
                            borderTop: '1px solid #ddd',
                            backgroundColor: 'background.default',
                            borderBottomLeftRadius: '8px',
                            borderBottomRightRadius: '8px',
                            zIndex: 1
                        }}
                    >
                        <Button onClick={onClose} variant="outlined" sx={{ mr: 1 }}>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" variant="contained" sx={{
                            margin: 2,
                            bgcolor: '#C7FD06',
                            color: 'black',
                            '&:hover': {
                                bgcolor: '#C7FD06',
                            },
                        }}>
                            {property ? 'Atualizar' : 'Criar'}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
    
};

export default CreatePropertyForm;